'use strict';

const debounce = require('lodash/debounce');
const utils = require('../util/utils');

/**
 * Initializes event for tabs
 */
function clickOnTab() {
    var model = this;
    $('.js-widget-tab').on('click', function (e) {
        e.preventDefault();
        $('.js-widget-tab').removeClass('active');
        let $this = $(this);
        model.baseUrl = $this.closest('a').attr('href');

        if (model.baseUrl) {
            $.ajax({
                url: model.getUpdateUrl(),
                beforeSend: $.spinner().start,
                complete: $.spinner().stop,
                success: function (data) {
                    $('.js-product-grid').html(data);
                    $this.addClass('active');
                }
            });
        }
    });
}

/**
 * Makes a product search based on search term and active tab
 */
function makeSearch() {
    var model = this;
    if (model.$searchField.val().length >= model.minChars || model.$searchField.val().length === 0) {
        $.ajax({
            url: model.getUpdateUrl(),
            method: 'GET',
            beforeSend: $.spinner().start,
            complete: $.spinner().stop,
            success: function (data) {
                $('.js-product-grid').html(data);
                if (model.$searchField.val().length >= model.minChars) {
                    model.$resetBtn.removeClass('d-none');
                }
            }
        });
    } else {
        model.$resetBtn.addClass('d-none');
    }
}

/**
 * Initializes event for the search field
 */
function search() {
    var model = this;
    $('.js-product-widget-search form').on('submit', function (e) {
        e.preventDefault();
    });

    $('input.js-widget-search-field').each(function () {
        var callbackFunction = debounce(makeSearch.bind(model), 400);
        $(this).on('keyup focus', function (e) {
            callbackFunction(this, e);
        });
    });

    var isDesktop = utils.mediaBreakpointUp('md');
    if (isDesktop) {
        $('body').on('mouseenter', '.product-tile', function () {
            $(this).find('.product-short-description').addClass('d-block');
        }).on('mouseleave', '.product-tile', function () {
            $(this).find('.product-short-description').removeClass('d-block');
        });
    } else {
        $('body').on('click', '.product-tile', function () {
            $(this).find('.product-short-description').addClass('d-block');
        }).on('blur', '.product-tile', function () {
            $(this).find('.product-short-description').removeClass('d-block');
        });
    }
}

/**
 * Initializes event for the reset button
 */
function reset() {
    var model = this;
    model.$resetBtn.on('click', function () {
        model.$searchField.val('');
        makeSearch.call(model);
        model.$resetBtn.addClass('d-none');
    });
}
const WidgetModel = function () {
    this.baseUrl = $('.js-widget-tab.active').closest('a').attr('href');
    this.$searchField = $('input.js-widget-search-field');
    this.$resetBtn = $('.js-product-widget-search .js-product-widget-reset-button');
    this.searchField = this.$searchField[0];
    this.minChars = 3;
    this.init();
};

WidgetModel.prototype.init = function () {
    clickOnTab.call(this);
    search.call(this);
    reset.call(this);
};

WidgetModel.prototype.getUpdateUrl = function () {
    var searchTerm = $('input.js-widget-search-field').val();
    var finalURL = this.baseUrl;
    if (searchTerm && searchTerm.length >= this.minChars) {
        finalURL = utils.appendParamToURL(finalURL, 'q', searchTerm);
    }
    return finalURL;
};

module.exports = new WidgetModel();
